<template>
  <div>
    <div class="header-info">
      <el-row>
        <span class="info-span info-top__color" style="margin-top: 25px">{{ date }}</span>
      </el-row>
      <el-row>
        <span class="info-span info-span__color">ODM制造商：{{ manufacturer }}</span>
      </el-row>
      <el-row>
        <span class="info-span info-span__color">生产种类：{{ type }}</span>
      </el-row>
      <el-row>
        <span class="info-span info-span__color" style="margin-bottom: 25px">生产总数：{{ total }}</span>
      </el-row>
    </div>
    <div class="report-info">
      <el-table
          ref="table"
          :data="tableData"
      >
        <el-table-column align="center" label="生产物料" prop="model.name"/>
        <el-table-column align="center" label="生产数量" prop="productionNum"/>
      </el-table>
    </div>
    <el-row style="margin-top: 50px">
      <el-button @click="callBack">返回</el-button>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: '',
      year: '',
      manufacturer: '',
      type: 0,
      total: 0,
      tableData: [],
      accountId: ''
    }
  },
  created() {
    const reportInfo = JSON.parse(decodeURIComponent(this.$route.query.data))
    this.year = reportInfo.list[0].year
    this.date = reportInfo.list[0].year + ' 年 ' + reportInfo.month + ' 月生产月报'
    this.manufacturer = reportInfo.manufacturerName
    this.type = reportInfo.productionType
    this.total = reportInfo.productionTotal
    this.tableData = reportInfo.list
    this.accountId = reportInfo.list[0].manufacturer.accountId
  },
  methods: {
    callBack() {
      this.$router.push({
        path: '/odm/report/list',
        query: {
          accountId: this.accountId,
          year: this.year
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.header-info {
  background: #f2f2f2;
}
.info-span {
  margin: 10px 0 0 30px;
}
.info-top__color {
  color: #333333;
}
.info-span__color {
  color: #7f7f7f;
}
.report-info {
  margin-top: 50px;
}
</style>
